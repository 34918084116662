.faceContainer {
  position: relative;
  height: 100%;
  width: 100%;
  box-shadow: -5px 5px 15px 5px #2f2f2f, 2px -2px 10px 1px #c9eac1;
  border-radius: 100%;
  overflow: hidden;

  .mirror {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    box-shadow: inset -5px 5px 50px 10px #2f2f2f;
    background-color: #a1a1a1;
    transition: background-color, box-shadow, 1s ease-in-out;
    border: 20px solid #7a7a7a;

    &:hover {
      background-color: unset;
      box-shadow: inset 0 0 20px 5px #2f2f2f;
      cursor: pointer;
    }
  }

  .face {
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  .shea,
  .liam,
  .matt {
    position: absolute;
  }

  .shea {
    width: 25%;
    height: 5%;
    top: 30%;
    left: 50%;
  }

  .liam {
    width: 35%;
    height: 25%;
    top: 15%;
    left: 22.5%;
  }

  .matt {
    width: 55%;
    height: 30%;
    top: 20%;
    left: 20%;
  }
}
